import React from 'react';
import NatureGuide from '../../../../components/guides/NatureComponent';
import Footer from '../../../../components/FooterComponent';

import { NinhBinh } from '../../../../guides/countries/vietnam/ninh-binh';

function NinhBinhPage(props) {
    return (
        <div>
            <NatureGuide nature={ NinhBinh } pathname={ props.location.pathname } urlBack="/travel-guides/asia/vietnam" />
            <Footer />
        </div>
    );
}

export default NinhBinhPage;
