import React from 'react';

export const NinhBinh = {
    "name": "Ninh Binh",
    "country": "Vietnam",
    "continent": "Asia",
    "thumb": "https://live.staticflickr.com/65535/50380652177_bfb3161e11_t.jpg",
    "image": "https://live.staticflickr.com/65535/50380652177_6ce72e9134_o.jpg",
    "caption": "Ha Long Bay On Land",

    "brief": "With hundreds of limestone monoliths in a dense greenery scenario, and rivers flowing all around the karsts, Ninh Binh is a natural wonder to behold. The Trang An landscape, part of Ninh Binh, was inscribed as a UNESCO World Heritage Site in 2014, and goes to show the beauty of this place, justifying the boom in tourism that is happening here.",

    "description": <p>
        Ninh Binh is located in the Red River Delta region of northern Vietnam. This province is referred to as "Ha Long Bay On Land", due to its high density of limestone karsts and many rivers used to navigate between the amazing scenery. Many movies have been filmed on these landscapes, with one of the most famous being King Kong.
        <br/><br/>
        The first capital of Vietnam was actually established here, and you will see remnants of it on its many pagodas and temples spread all over the place. Nowadays, Ninh Binh is a place embedded in nature, with endless rice fields, tall limestones karsts and amazing caves, all interconnected through its many rivers.
        <br/><br/>
        Despite being a big region, most tourists come here to visit two sites, the Trang An Landscape, declared a UNESCO World Heritage Site in 2014, and the Tam Coc Landscape. The best way to experience any of them is by doing a boat tour. You can also explore Ninh Binh on your own by renting a bike or a scooter and drive through all the rice paddies and admire the views.
        </p>,

    "nrDays": "Although most people come here as a day trip from Hanoi, we firmly believe one day is not enough for this gem. You should spend at least 2 days, and experience the best things Ninh Binh has to offer.",

    "whenVisit": <p>
        Ninh Binh can be visited at any time of the year. Like the rest of Northern Vietnam, there are two optimal times to visit. One of them is from <b>May to August</b>, when the weather is warm and humid. The other one is from <b>February to April</b>, and it's the one we recommend, when the weather is not too warm yet, but good enough to go visit and the amount of tourists is not huge.
        <br/><br/>
        We recommend visiting between <b>May to June</b>, it's when the rice paddies will be with an amazing yellow-green color, creating a splendid scenery.
        </p>,

    "getHere": <p>
            Whether you are coming from the north or south you have two options, train and bus. The train that connects Hanoi to Hue stops at Ninh Binh, and it's the one you will be taking if you choose this option. It's the most comfortable way to travel to Ninh Binh, but on the downside the train stop is a few kilometers away from Tam Coc.
            <br/><br/>
            In our opinion the bus is the better option here, it's cheaper and has a stop right in the centre of Tam Coc. You have bus options departing from Hue and Phong Nha on the south, and also from Hanoi and Ha Long Bay in the north. You can check the bus schedules at <a href="https://www.thesinhtourist.vn/busschedule" target="_blank" rel="noopener noreferrer">The Sinh Tourist</a>, and also on <a href="http://www.mailinhexpress.vn/" target="_blank" rel="noopener noreferrer">Mai Linh Express</a> and on <a href="https://north-vietnam.com/vietnam-bus-schedule-timetable-bus-tickets/" target="_blank" rel="noopener noreferrer">Northern Vietnam</a>.
        </p>,

    "mobility": <p>
            Ninh Binh is a great place to travel on your own. In fact, one of the highlights here is just to drive along the endless rice paddies with limestone karsts in the background. For this, the best option is to rent a motorbike on one of the many hotels/hostels that rent them. They usually go for about 4$ a day.
            <br/><br/>
            Another option for those afraid or inexperienced on riding a motorbike is to rent a bicycle. The land on Ninh Binh is pretty much flat, and makes cycling a good option. Keep in mind that some places like <b>Bai Dinh Padonga</b> are quite a few kilometers away from the city.
        </p>,

    "activities": {
        "description": "As previously said, the best way to experience Ninh Binh is by doing a boat tour. The boats usually carry 2 to 4 people, and you wait in line until it is full before departure. There are 2 tours you can choose from (or do both for the ultimate experience):",
        "activities": [
            {
                "name": "Tam Coc Boat Ride",
                "image": "https://live.staticflickr.com/65535/50514740037_42a2ba33ba_o.jpg",
                "description": <div>
                        <ul>
                              <li><b>Price:</b> 15$ for two people</li>
                              <li><b>Average Duration:</b> 2-3 hours</li>
                        </ul>
                        <p>
                        This is the most famous boat tour on Ninh Binh. You will go around an area used for agriculture, where you will see lots of rice paddies. If you are visiting between May to June, the rice paddies will be a sight to behold. This is the reason why, undeniably, this boat tour is the most beautiful and famous, and you are sure to get amazing photographs at this time of the year.
                        <br/><br/>
                        Along the tour, you will pass through many limestone karsts, visit three caves and navigate along an incredible area. You will also see a very interesting maneuver the locals use to drive the boat with their feet.
                        <br/><br/>
                        Being the number one attraction in Ninh Binh, Tam Coc is usually packed with tourists, which takes back on the experience. There are also some rumors of boat riders asking passengers for money in the middle of the river, it did not happen to us, but be on the alert.
                        </p>
                    </div>
            },
            {
                "name": "Trang An Boat Ride",
                "image": "https://live.staticflickr.com/65535/50513862333_69e3b3192b_o.jpg",
                "description": <div>
                        <ul>
                              <li><b>Price:</b> 7$</li>
                              <li><b>Average Duration:</b> 2-3.5 hours</li>
                        </ul>
                        <p>
                        While it does not draw the crowds of Tam Coc, this is a great boat ride on its own. You will pass through limestone karsts, caves, temples and lakes along your journey. It has more variety than the previous tour, and can be enjoyed at any time of the year.
                        <br/><br/>
                        There are three main routes you can take on this boat tour. Route 1 is the longest route and takes you through 9 different caves. The shortest route is Route 2 (better for families and kids), and stops at a region used to film a King Kong movie. Fans of the movie will immediately recognize the scenery. The last route, Route 3, is the best if you want to see lots of karst mountains.
                        This is the option to take for those that like things more "off the beaten path".
                        <br/><br/><br/>
                        After experiencing the boat tours. It's time to drive around Ninh Binh and soar in the nature of this place. There are some interesting spots you should check.
                        </p>
                    </div>
            },
            {
                "name": "Mua Caves",
                "image": "https://live.staticflickr.com/65535/50514576716_12b5baedd1_o.jpg",
                "description":
                    <div>
                        <ul>
                              <li><b>Entrance fee:</b> 4$</li>
                        </ul>
                    <p>
                    This places has one of the best panoramic views of the scenery of Ninh Binh, but you have to walk 486 steps to get to the very top. From here you can also explore the Dragon Peak statue and the Goddess of Mercy's Shrine. Mua caves usually closes at 5pm.
                    </p>
                    </div>,
            },
            {
                "name": "Hoa Lu Ancient Village",
                "image": "https://live.staticflickr.com/65535/50514740017_c4c0ede078_o.jpg",
                "description": "The first capital of Vietnam. Hoa Lu reached its peak around the 10th and 11th centuries, when the surrounding environment offered protected from invasions. Nowadays, there's two structures from the ancient capital you can visit."
            },
            {
                "name": "Bai Dinh Pagoda",
                "image": "https://live.staticflickr.com/65535/50514740012_c41584be44_o.jpg",
                "description": "This is the largest Buddhist temple in Southeast Asia, and features a building standing at an impressive 13-floors. The downside is the location, around 20 km away from Ninh Binh."
            }
        ]
    },

    "food": {
        "description": <p>
                    Perhaps the most famous dish in Ninh Binh is the <b>mountain goat meat</b> and <b>crispy rice</b>, and you see them in the menu all over the place.
                    While most hotels and homestays provide good meals, we leave some suggestions if you want to try something around town.
                </p>,

        "restaurants": [
            {
                "name": "Thuy Linh Restaurant",
                "description": "The place you want to visit in Tam Coc to try some goat meat. Cheap prices, great food, what more can you ask for?",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50514740072_3dfe532612_o.jpg"
            },
            {
                "name": "Aroma Indian Restaurant",
                "description": "A famous indian restaurant in Tam Coc, the speciality here is the curry. This one is a little bit more expensive than other restaurants in the area, but it's justified by the quality of the food.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50513862383_b03c331bdb_o.jpg"
            }
        ]
    },

    "stay": {
        "description": <p>
                The city of Ninh Binh is an uninspiring, ugly city, and you should not commit the same mistake many tourists do when they stay there. Instead you should look for accommodations in <b>Tam Coc Village</b>, a much more pleasant place and close to all the attractions in Ninh Binh. If you wish for a more "<i>countryside</i>" option, surrounded by nature, you should check for accommodations in <b>Trang An Village</b>. The only downside here is it's a bit far away from most attractions.
                <br/><br/>
                We will give you some recommendations of what we believe are good places to stay.
            </p>,

        "accomodations": [
            {
                "name": "Zuli Zen Homestay",
                "image": "https://live.staticflickr.com/65535/50513862478_55357fc3be_o.jpg",
                "description": "A great accommodation in Tam Coc for those on a budget, and within a 10-15 minute walk from most places in Tam Coc. The staff here is very friendly and will help you with anything you need, including on how to rent scooters or bikes. This accommodation offers great quality for the price you pay.",
                "link": ""
            },
            {
                "name": "Tam Coc Tropical Homestay",
                "image": "https://live.staticflickr.com/65535/50514576836_559ac8a2c7_o.jpg",
                "description": "Another very pleasant accommodation in Tam Coc, you are right at the door from all the interesting places in town. The staff is great and the rooms are very clean. Also includes a fabulous breakfast to help you kickstart your day.",
                "link": ""
            },
            {
                "name": "Chez Loan Hotel",
                "image": "https://live.staticflickr.com/65535/50514576796_3ffcaa9524_o.jpg",
                "description": "This is a famous hotel in Tam Coc, boasting some great views to the mountain karsts. Is within a 5 minute walk from most restaurants and interesting places in Tam Coc Village. The rooms are very clean and well equipped. The pool is a nice addition after a day exploring the landscape.",
                "link": ""
            },
            {
                "name": "Trang An Retreat",
                "image": "https://live.staticflickr.com/65535/50514740107_26ee5e12de_o.jpg",
                "description": "For those seeking a pleasant stay in the countryside, this is the best accommodation in Trang An Village. Has everything you should expect from a great accommodation. It's a great place to relax with the surrounding nature.",
                "link": ""
            }
        ]
    }
}
